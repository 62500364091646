.Sections {
  h2{
    margin-bottom: 50px;
  }
}

.SectionsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;

  > a {
    &:first-child {
      height: 520px;
      background-position-x: 100%;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:last-child {
      grid-column-start: 2;
      grid-column-end: 4;
    }


    min-height: 250px;
    background-position-x: 100%;
    background-position-y: 100%;
    position: relative;
    border: 1px solid #D5D7E6;
    border-radius: 10px;
    background-repeat: no-repeat;
    transition: 0.5s;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            z-index: 1;
            box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
            border-color: #fff;
        }
    }

    h3 {
      width: 90%;
      color: #282F36;
      left: 20px;
      font-weight: 600;
      font-size: 20px;
      position: absolute;
      top: 25px;
      margin: 0;
      padding: 0;
    }
    div {
      width: 90%;
      color: #7b7b7b;
      left: 20px;
      position: absolute;
      top: 60px;
      font-size: 14px;
      max-width: 180px;
      font-weight: normal;
    }
  }
}

@media only screen and (max-width: 991px) {
  .SectionsList {
    grid-template-columns: 6fr 4fr 4fr;
  }
  .SectionsList > a {


    &:not(:first-child) {
      grid-column-start: 2;
      grid-column-end: 4;
    }

    &:last-child {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

@media only screen and (max-width: 768px) {


  .Sections {
    h2{
      margin-bottom: 30px;
    }
  }

  .SectionsList {
    grid-template-columns: 1fr 1fr;
    > a{
      min-height: 250px;
      background-position-x: 50%;
      background-size: contain;
      &:first-child {
        height: 40vw;
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &:not(:first-child) {
        grid-column-start: inherit;
        grid-column-end: inherit;
      }

      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
        background-position-x: 100px;
      }

      h3 {
        left: 20px;
        font-weight: 600;
        font-size: 16px;
        top: 20px;
      }
    }
  }
}
