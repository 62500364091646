.Models {

    h2 {

        span {
            color: #a0a0a0;
            margin-left: 20px;
            font-weight: normal;
        }
    }
}

.Items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
}

.Item {
    background-color: #fff;
    height: 170px;
    background-repeat: no-repeat;
    background-position: 100%;
    border-radius: 20px;
    padding: 20px;
    font-weight: bold;
    font-size: 18px;
    color: #333;
}


@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 761px) {

}