
.Main {
  display: flex;
  margin: 80px 0;

  > div {
    width: 50%;
  }

  h1 {
    margin: 0;
  }

  &Info {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }

  > div {
    line-height: 2em;
    font-size: 16px;
  }

  &Logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .Main {
    flex-direction: column-reverse;
    align-items: center;

    &Info {
      width: 100% !important;
    }
  }
}