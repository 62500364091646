.Landings {
    margin-top: -125px;
    background-color: #F2F2F2;
}

.MainPage {
    color: red;
    > div {
        margin-bottom: 50px;
    }
}

.Top {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -100px 0 80px;
}

.Bottom {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -170px 0 80px;

    > div {
        z-index: 1;
        &:first-child h2{
            color: #fff;
        }
    }
}

.News {
    display: flex;
    gap: 30px;

    > div {
        width: 50%;
    }
}


@media only screen and (max-width: 991px) {
    .News {
        display: flex;
        flex-direction: column;
        gap: 30px;
        > div {
            width: 100%;
        }
    }
    .Top {
        display: flex;
        gap: 60px;
        flex-direction: column;
        margin: -80px 0 60px;
    }

    .Bottom {
        display: flex;
        gap: 40px;
        flex-direction: column;
        margin: -200px 0 60px;
    }
}


@media only screen and (max-width: 600px) {
    .Top {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -80px 0 30px;
    }

    .Bottom {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -230px 0 30px;
    }
}

.LandingsModels {
    margin: 80px 0;
}

.LandingsGray {
    background-color: #212E38;
    color: #fff;
    display: flex;
}

.LandingsBloks {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin: 80px 0;
}