.Numbers {
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.Items {
  display: flex;
  margin: 50px 0;
  justify-content: space-between;
}

.Value {
  margin-bottom: 10px;
}

.Name {
  color: #fff;
  font-size: 18px;
}

.Num {
  font-size: 40px;
  font-weight: bold;
  display: inline-block;
  margin: 5px 5px 5px 0;
}

.Text {
  padding: 24px 0;
  max-width: 600px;
  line-height: 32px;
  position: relative;
  a {
    color: #80BAFF;
  }


}


@media only screen and (max-width: 991px) {
  .Items {
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 49%;
      margin-bottom: 40px;
    }
  }
}


@media only screen and (max-width: 600px) {
  .Numbers {

  }
  .Num {
    font-size: 20px;
  }
  .Items > div {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }
}