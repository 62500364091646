.Title {
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: bold;
}

.Item {
    display: flex;
    gap: 20px;
}

.Videos {
  margin-bottom: 40px;
  align-items: center;
  margin-top: -40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}

.Item {
    cursor: pointer;
    position: relative;
    img {
        border-radius: 8px;
    }

    svg {
        position: absolute;
        top: calc(50% - 34px);
        left: calc(50% - 35px);
    }
}

@media only screen and (max-width: 991px) {
  .Videos {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -40px;
  }
}

@media only screen and (max-width: 600px) {
  .Videos {
    grid-template-columns: 1fr 1fr;
    margin-top: -30px;
  }
}