.Contacts {
  display: flex;
  margin-bottom: 50px;
  gap: 20px;


  &Right {
    width: 33%;
  }

  h2 {
    font-size: 30px;
    margin: 0;
  }
}

.Top {
  height: 80px;
}

.Selector {
  font-size: 20px;
  display: flex;
  gap: 40px;

  span {
    margin-top: 10px;
    cursor: pointer;
  }
}

.Selected {
  color: #adadad;
}

.Info {
    display: flex;
    gap: 40px;
    margin: 30px 0;

    > div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.Map {
    width: 66%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.Time {
  color: #BBBFC2;
  font-size: 14px;
}

.Adreses {
  display: flex;
  flex-direction: column;
  background-color: #212E38;
  color: #fff;
  border-radius: 20px;
  padding: 20px 40px;
  line-height: 1.5em;
}

.Requisite {
    > div {
  display: flex;
  margin: 0 0 50px 0;
  padding: 50px 0;
  gap: 30px;

  > div {
    width: 50%;
  }

    h2 {
      font-size: 18px;
      margin: 0;
      padding: 18px 0;
  }

  p {
    margin: 0;
    color: #9294A9;
    border-top: 1px solid #D5D7E6;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 15px;

    span:first-child {
    }

    span:last-child {
        color: #0F1323;
        text-align: right;
    }


    &:last-child {
        border-bottom: 1px solid #D5D7E6;
    }
  }
}
}

.AllShops {
  margin-bottom: 50px;
  > div {
    display: flex;
    flex-direction: column;
  }
}

.Adreses {
    display: flex;
    z-index: 1;

}

.Adr {
    width: 400px;
    margin-right: -400px;
    z-index: 1;
    padding: 70px 0 0 50px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.AdrBlock {
    padding: 20px 0;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    border-bottom: 1px solid #000;

    > span {
        margin-bottom: 5px;
    }

    &:last-child {
      border-bottom: none;
    }
}

.Scheme {
    cursor: pointer;
    color: #FADE74;
}

.SchemeSelected {
    color: #a28200 !important;
}

.Images {

}

.Blocks {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.Video {
    iframe {
        width: 100%;
        height: 500px;
    }
}
@media only screen and (max-width: 991px) {
    .Adr {
        z-index: 1;
        padding: 0 0 30px;
        display: flex;
        grid-gap: 12px;
        gap: 12px;
        margin-right: 0;
        flex-direction: row;
        width: inherit;
    }

    .Adreses {
        flex-direction: column;
    }

}

@media only screen and (max-width: 768px) {
    .Requisite {
        flex-direction: column;
        padding: 20px 0;

        > div {
            width: 100%;
        }
        p {
            padding: 10px 0;
            font-size: 14px;
        }
    }

    .Contacts {
        /*margin: 0;*/
    }

    .Info {
        margin: 0 0 20px;
    }
    .Blocks {
        gap: 30px;
    }

  }

  @media only screen and (max-width: 500px) {
    .Adr {
        flex-direction: column;
    }
  }