.Slider {
    margin-top: -80px;
    margin-bottom: 0px;
}

.Back {
    height: 730px;
    background-size: cover;
    background-position: 50%;
}

.Title {
    font-size: 76px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}


.Content {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 730px;
    justify-content: center;
    gap: 20px;
    align-items: flex-start;
    
    h1 {
        font-weight: 600;
        font-size: 23px;
        color: #fff;
        margin: 0;
        border-radius: 5px;
    }

    a {
        color: #fff;
        display: flex;
        gap: 10px;
        align-items: center;
        span {
            width: 50px;
            height: 50px;
            display: flex;
            border-radius: 50%;
            border: 1px solid #fff;
            align-items: center;
            justify-content: center;
        }
    }
}


@media only screen and (max-width: 991px) {
    .Back {
        height: 600px;
    }
    .Content {
        height: 600px;
        width: 100%;
        align-items: center;
    }
    .Title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 551px) {
    .Content {
        align-items: center;
        text-align: center;
        h1 {
            font-size: 20px;
        }
    }
    .Title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .Back {
        background-position: 70%;
    }
}