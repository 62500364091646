.Slider {
    margin-bottom: 0px;
}

.Back {
    height: 730px;
    background-size: cover;
    background-position: 50%;
}

.Title {
    font-size: 18px;
    color: #fff;
}


.Content {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 730px;
    justify-content: center;
    gap: 40px;
    align-items: flex-start;
    
    h1 {
        font-weight: 600;
        font-size: 45px;
        color: #fff;
        margin: 0;
        border-radius: 5px;
    }
}


@media only screen and (max-width: 991px) {
    .Back {
        height: 50vw;
        min-height: 300px;
    }
    .Content {
        width: 100%;
        height: 35vw;
        min-height: 200px;
    }
    .Title {
    }
}

@media only screen and (max-width: 761px) {
    .Content {
        align-items: center;
        text-align: center;
        h1 {
            font-size: 20px;
        }
    }
    .Title {
        font-size: 16px;
    }
}