.News {
  display: inline-block;

  h2 {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 16px;
    }
  }
}

.NewsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;

  > a {
    color: #333;
    background-color: #fff;
    border-radius: 10px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #D5D7E6;
    min-height: 360px;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            box-shadow: 0px 20px 40px rgb(0 0 0 / 15%);
            .Info {
                border-color: #fff;
            }
        }
    }
  }
  img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
}


.Picture {
  display: flex;
  overflow: hidden;
}

.Name {
    font-weight: 600;
  font-size: 18px;
}


.Info {
    padding: 25px 30px 16px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: none;
    border-radius: 0 0 8px 8px;
    gap: 15px;
    flex: 1;
}

.Bottom {
  font-size: 15px;
  color: #93979b;
}

.ShowAll {
  width: 100%;
  background-color: #fff;
  display: flex;
  margin-top: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  height: 48px;
}

@media only screen and (max-width: 1100px) {
  .Name {
    font-size: 16px;
  }
  .Bottom {
    font-size: 14px;
  }
  .Info {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .NewsList {
    grid-template-columns: 1fr 1fr 1fr;

    > a {
      min-height: 260px;
    }
  }


}


@media only screen and (max-width: 600px) {
  .NewsList {
    grid-template-columns: 1fr 1fr;
  }

  .Info {
    padding: 10px;
    height: min-content;
  }
  .Name {
    font-size: 14px;
  }
}