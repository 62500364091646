.About {
  display: flex;
  justify-content: space-between;
  gap: 100px;

  a {
    color: #FADE74;
    display: flex;
    align-items: center;
  }
}

.Text {
  max-width: 700px;
  font-size: 18px;
  line-height: 2.2em;
}

.Info {
  min-width: 200px;

  h2 {
    margin-bottom: 30px;
    font-size: 36px;
  }
}