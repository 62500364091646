.Numbers {
  display: flex;
  flex-direction: column;
  background-color: #131f37;
  color: #fff;
  padding-bottom: 246px;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    background: url(/back.jpg);
    width: 1094px;
    height: 835px;
    bottom: 0;
    left: 45%;
  }
}

.Items {
  display: flex;
  justify-content: space-between;
  gap: 1%;
  padding: 60px 0;
  border-bottom: 2px solid #E5E5E52b;
  z-index: 1;
  position: relative;
  flex-wrap: wrap;
  > div {
    width: 24%;
  }
}

.Value {
  font-size: 16px;
}

.Name {
  color: #9198AD;
}

.Num {
  font-size: 40px;
  display: inline-block;
  margin: 5px 5px 5px 0;
}

.Text {
  padding: 24px 0;
  max-width: 600px;
  line-height: 32px;
  z-index: 1;
  position: relative;
  a {
    color: #80BAFF;
  }


}


@media only screen and (max-width: 991px) {
  .Items {
    padding-bottom: 20px;
    > div {
      width: 49%;
      margin-bottom: 40px;
    }
  }
}


@media only screen and (max-width: 600px) {
  .Numbers {

  }
  .Num {
    font-size: 30px;
  }
  .Items > div {
    margin-bottom: 20px;
  }
}