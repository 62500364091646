.Questions {
  display: flex;
  gap: 50px
}

.Right {
  display: flex;
  flex-direction: column;
  gap: 25px;

  h2 {
    margin: 0;
    font-size: 30px;
  }

  > div {
    width: fit-content;
  }
}

.Selected {
  color: #fff;
}

.Question {
  width: 66%;
  display: flex;
  flex-direction: column;
  > li {
    border-bottom: 1px solid #6c6c6c;
    padding: 0 18px;
    cursor: pointer;
    position: relative;
    &:last-child {
      border-bottom: none;
    }
  }
}
.SectionName {
  font-size: 13px;
  color: #93979b;
   margin-bottom: 8px;
}

.Name {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  line-height: 1.5em;
  min-height: 96px;
  display: flex;
  align-items: center;
  max-width: 90%;
}

.Text {
    margin: 20px 0;
    line-height: 2em;
}

.Arrow {
  border: 1px solid #fff;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff;
  }

  &Selected {
    background-color: #FADE74;
    border: 1px solid #FADE74;

    svg {
      fill: #333;
      transform: rotate(180deg);
    }
  }
}

@media only screen and (max-width: 768px) {
  .Menu {
    margin: 0 0 30px;
  }

  .Name {
    font-size: 16px;
  }
}