.Top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;

  h2 {
    margin: 0;
    font-size: 24px;
  }


    &[data-type="mark"] {
        .Reset {
              display: none;
          }
    }

}

.Selector {
  position: relative;
}

@media only screen and (min-width: 992px) {
    .Top {
        &[data-type="mark"] {
            .Select {
                display: none;
            }
        .Reset {
                display: none;
            }
        }
    }
}

.Symbols {
    display: flex;
    flex-direction: column;
    height: 1300px;
    flex-wrap: wrap;
    gap: 30px 50px;
}

.Symbol {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
    border-top: 1px solid #D8D8D8;
    padding-top: 15px;
    > span {
        color: var(--main-theme);
        font-weight: 600;
    }
}

.Marks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    li {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        width: 100%;
        height: 30px;
    }
    img {
        width: 30px;
    }
}

.Select {
  min-width: 200px;

  &[data-value="0"] {
      color: #9294A9;
  }
}

.TopMarks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  li {
    cursor: pointer;
    justify-content: center;
  }
}

.Items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
  padding: 0;
  gap: 10px;
  li {
    background-color: #fff;
    padding: 9px;
    font-size: 18px;
    border-radius: 5px;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    position: relative;
    width: calc(16.66% - 10px);
    display: flex;
    flex-direction: column;
    border: 1px solid #D8D8D8;
    transition: 0.3s;
    color: #333;
    &:hover{
        border-color: var(--main-theme);
    }
  }

   &Models {
      flex-direction: column;
      max-height: 200px;
  }
}

.SectionsItems {
    display: grid;
    flex-wrap: wrap;
    margin: 20px 0;
    padding: 0;
    gap: 20px 30px;
    grid-template-columns: 1fr 1fr 1fr;
    a {
        color: #333;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #D8D8D8;
        gap: 10px;
    }
}

.SectionsTitle {
    margin: 20px 0;
    font-size: 24px;
}

.Count {
  color: #93979b;
}

.Gen {
  color: #93979b;
  font-size: 16px;
}

.Sections {
  display: flex;
  flex-wrap: wrap;
}

.Bottom {
  border-top: 1px solid #dfe0e1;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
}

.Reset {
    cursor: pointer;
    color: var(--main-theme);
}

.Loader {
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #ffffffb0;
    border-radius: 10px;
    transition: 0.3s;
}

@media only screen and (max-width: 991px) {
    .Top {
        flex-direction: column;

        h2 {
            width: 100%;
        }
    }

.Select {
        width: 100%;
    }


  .Items li{
    width: calc(25% - 8px);
  }

    .Symbols {

      height: 1600px;
      gap: 20px;

  }
}

@media only screen and (max-width: 762px) {
    .Symbols {
        height: 2400px;
        gap: 20px;
    }
    .Symbol {
        gap: 15px;
    }

    .SectionsItems {
        grid-template-columns: 1fr 1fr;
    }
  .Items li{
        width: calc(50% - 6px);
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
  .Top h2{
    font-size: 22px;
  }
    .Symbol {
      gap: 5px;
      flex-direction: column;
  }
    .Symbols {
      height: 2350px;
  }
    .Marks {
      gap: 5px;
  }
}