.Baltex {
    background-color: #fff;
    display: inline-block;
    width: 100%;
}

.MainPage {
    color: red;
    > div {
        margin-bottom: 50px;
    }
}

.Top {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -100px 0 80px;
}

.Bottom {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -170px 0 80px;

    > div {
        z-index: 1;
        &:first-child h2{
            color: #fff;
        }
    }
}

.News {
    display: flex;
    gap: 30px;

    > div {
        width: 50%;
    }
}


@media only screen and (max-width: 991px) {
    .News {
        display: flex;
        flex-direction: column;
        gap: 30px;
        > div {
            width: 100%;
        }
    }
    .Top {
        display: flex;
        gap: 60px;
        flex-direction: column;
        margin: -80px 0 60px;
    }

    .Bottom {
        display: flex;
        gap: 40px;
        flex-direction: column;
        margin: -200px 0 60px;
    }
}


@media only screen and (max-width: 600px) {
    .Top {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -80px 0 30px;
    }

    .Bottom {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -230px 0 30px;
    }
}

.BaltexModels {
    padding: 80px 0;
    background-color: #F2F2F2;
}

.BaltexCatalog {
    margin: 80px 0;
    background-color: #fff;
}

.BaltexGray {
    background-color: #212E38;
    color: #fff;
    display: flex;
}

.BaltexBloks {
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin: 80px 0;
}

.BaltexNumbers {
    background-color: #111112;
}

.Video {
    height: 300px;
    background-image: url("/baltex/video.jpg");
    background-position: 50%;
    content: '';
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    cursor: pointer;

    > span {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Sert {
    margin-top: 50px;
    background-color: #111112;
    height: 116px;
    display: flex;
    color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    > span {
        font-size: 24px;
    }

    a {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        align-items: center;
        color: #fff;

        > span {
            border-radius: 50%;
            border: 1px solid #fff;
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
@media only screen and (max-width: 991px) {
    .Video {
        height: 100% !important;
    }
}