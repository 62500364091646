.News {
  display: flex;
  justify-content: space-between;

  h2 {
    display: flex;
    flex-direction: column;
    a {
      margin-top: 15px;
      font-size: 14px;
      display: flex;
      color: #161616;

    }
  }
}

.NewsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 66.6%;

  img {
    border-radius: 8px 8px 0 0;
  }
}

.Bottom {
  font-size: 15px;
  color: #93979b;
}

.Item {
  margin: 20px 0;
  width: 100%;
  display: inline-block;
}

.Button {
  font-size: 20px;
  border-radius: 10px;
  margin: 1%;
  float: left;
  color: #282f36;
  background-color: #fff;
  height: 116px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.Qnt {
  font-size: 14px;
  color: #93979b;
}

.Rating {
  font-size: 34px;
}

@media only screen and (max-width: 1100px) {
  .Name {
    font-size: 16px;
  }
  .Bottom {
    font-size: 14px;
  }
  .Info {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .NewsList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media only screen and (max-width: 600px) {
  .NewsList {
    grid-template-columns: 1fr 1fr;
  }

  .Info {
    padding: 10px;
    height: min-content;
  }
  .Name {
    font-size: 14px;
  }
}


@media only screen and (max-width: 500px) {
    .Button {
        width: 98%;
        height: inherit;
    }
}