.Numbers {
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;
  position: relative;
  background: url(/baltex/numbersBack.jpg);
  border-radius: 10px;
}

.Items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  max-width: 700px;
  margin: 50px;
}

.Value {
  font-size: 16px;
}

.Name {
  color: #9198AD;
}

.Num {
  font-size: 36px;
  display: inline-block;
  margin: 5px 5px 5px 0;
}

.Text {
  padding: 24px 0;
  max-width: 600px;
  line-height: 32px;
  position: relative;
  a {
    color: #80BAFF;
  }


}


@media only screen and (max-width: 991px) {
  .Items {
    padding-bottom: 20px;
    > div {
      width: 49%;
      margin-bottom: 40px;
    }
  }
}


@media only screen and (max-width: 600px) {
  .Numbers {

  }
  .Num {
    font-size: 30px;
  }
  .Items > div {
    margin-bottom: 20px;
  }
}