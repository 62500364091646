.Adv {
  display: inline-block;
  h2{
    margin-bottom: 30px;
    font-size: 36px;
  }
}

.Items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}

.Item {
  background-color: #1D2A34;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px 40px;
  gap: 20px;
}

.Name {
  font-size: 21px;
}

.Text {
  font-size: 17px;
  line-height: 1.5em;
  color: #BBBFC2;
}

@media only screen and (max-width: 991px) {
  .AdvListRow_3 {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .AdvList > div {
    padding: 25px 5px;

    > span {
      line-height: 24px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .AdvListRow_3 {
      > div {
        padding: 15px 5px;
    
          > span {
            line-height: 20px;
            font-size: 14px;
            text-align: center;
          }
      }
  }
  .AdvListRow_2 {
    > div {
      padding: 15px 5px;
  
        > span {
          line-height: 20px;
          font-size: 14px;
          text-align: center;
        }
    }
}
  .AdvList > div {
    flex-direction: column;
  }
}